/* eslint-disable no-useless-escape */
import Vue from "@/helper/initVue";

import { RasmusForgotPassword } from "@/features/rasmus-kids-club/users/forgot_password";

export const initRasmusForgotPassword = () => {
  const el = document.getElementById("rasmus-forgot-password");

  if (!el) {
    return;
  }

  try {
    new Vue({
      el,
      render: (h) => h(RasmusForgotPassword),
    });
  } catch (err) {
    console.error('Failed to load module "RasmusForgotPassword"');
  }
};

initRasmusForgotPassword();
